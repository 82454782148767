import React, { Suspense, FC, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF, Html } from '@react-three/drei';

interface ModelViewerProps {
  modelPath: string;
}

const Model: FC<{ modelPath: string; autoRotate: boolean }> = ({ modelPath, autoRotate }) => {
  const { scene } = useGLTF(modelPath, true);
  const modelRef = useRef<any>(scene);

  // Используем `useFrame` для автоматического вращения модели
  useFrame(() => {
    if (autoRotate && modelRef.current) {
      modelRef.current.rotation.y += 0.005; // Плавное вращение
    }
  });

  return (
    <group ref={modelRef} position={[0, 0.6, 0]}> {/*регулировка высоты модельки*/}
      <primitive object={scene} scale={3.5} />
    </group>
  );
};

const ModelViewer: FC<ModelViewerProps> = ({ modelPath }) => {
  const [autoRotate, setAutoRotate] = React.useState(true);

  const handleStartInteraction = () => {
    setAutoRotate(false);
  };

  const handleEndInteraction = () => {
    setAutoRotate(true);
  };

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <Canvas>
        <ambientLight intensity={2} />
        <directionalLight position={[0, 10, 5]} intensity={5} /> {/*регулировка освещения*/}
        <Suspense fallback={<LoadingScreen />}>
          <Model modelPath={modelPath} autoRotate={autoRotate} />
        </Suspense>
        <OrbitControls
          enableZoom={false}
          onStart={handleStartInteraction}
          onEnd={handleEndInteraction}
        />
      </Canvas>
      <div style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'rgba(255, 255, 255, 0.8)',
        borderRadius: '50%',
        padding: '4px 8px',
        fontSize: '14px',
        color: '#333',
        fontWeight: 'bold',
        pointerEvents: 'none',
      }}>
        360°
      </div>
    </div>
  );
};

const LoadingScreen = () => (
  <Html center>
    <div style={{ color: 'white', fontSize: '1.5em' }}>Загрузка 3D модели...</div>
  </Html>
);

export default ModelViewer;